import {useEffect} from 'react';

import {Spinner} from '@polarsignals/components';
import type {NextPage} from 'next';
import {useRouter} from 'next/router';

import useActiveProject from 'hooks/data/useActiveProject';
import useProjects from 'hooks/data/useProjects';
import {hasNoProjects} from 'utils/projects';

const Home: NextPage = () => {
  const router = useRouter();
  const {
    loading,
    data: {activeProject},
  } = useActiveProject();
  const projectsData = useProjects();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (activeProject?.id) {
      router.replace(`/projects/${activeProject?.id}`);
    }
  }, [loading, activeProject, router]);

  useEffect(() => {
    if (projectsData.loading) {
      return;
    }
    if (hasNoProjects(projectsData.data.organizations)) {
      router.replace('/welcome');
    }
  }, [projectsData, router]);

  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner size={100} />
    </div>
  );
};

export default Home;
